<template>
<div>
  <Loading :isLoading= "this.isLoading" />
  <div>
    <p style="padding:14px 14px 0px 14px;font-size:20px;color:#174A84;font-weight:700;font-family:Lato;">Pagos parte social</p>
  </div>
  <div class="grid grid-cols-1 gap-4 m-5" style="">
    <div style="overflow-x:auto;background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;">
      <div class="grid grid-cols-2" style="padding:16px 16px 0 16px;">
        <div>
          <p style="font-size:15px;color:#174A84;font-weight:700;font-family:Lato;"></p>
        </div>
        <div>
          <img src="../../assets/Analyst/setting.svg" alt="" style="float:right;">
        </div>
        <div class="col-span-2">
          <div class="flex">
            <div class="form-group">
              <label style="color:#174A84;font-family:lato;font-weight:700;font-size:15px;">Buscar por R.F.C.</label><br>
              <input type="text" placeholder="Introducir R.F.C..." class="form-control searchBox" style="" id="buttonSearch" v-model="rfc" />
            </div>
          </div>
        </div>
      </div>
        <div class="">
          <table class="table width200" style="padding: 10px;" id= "table-id">
            <thead>
            <tr>
              <th v-on:click="sortTable('rfc')">
                <div class="grid grid-cols-2">
                  <div>
                    R.F.C.
                  </div>
                  <div>
                    <div class="arrow" v-if="'rfc' == sortColumn" v-bind:class="[ascending ? 'asc' : 'dsc']"></div>
                  </div>
                </div>
              </th>
              <th v-on:click="sortTable('name')">
                <div class="grid grid-cols-2">
                  <div>
                    Nombre
                  </div>
                  <div>
                    <div class="arrow" v-if="'name' == sortColumn" v-bind:class="[ascending ? 'asc' : 'dsc']"></div>
                  </div>
                </div>
              </th>
              <th v-on:click="sortTable('phone')">
                <div class="grid grid-cols-2">
                  <div>
                    Teléfono
                  </div>
                  <div>
                    <div class="arrow" v-if="'phone' == sortColumn" v-bind:class="[ascending ? 'asc' : 'dsc']"></div>
                  </div>
                </div>
              </th>
              <th v-on:click="sortTable('email')">
                <div class="grid grid-cols-2">
                  <div>
                    Correo
                  </div>
                  <div>
                    <div class="arrow" v-if="'email' == sortColumn" v-bind:class="[ascending ? 'asc' : 'dsc']"></div>
                  </div>
                </div>
              </th>
              <th v-on:click="sortTable('date_enroll_payment_fee')">
                <div class="grid grid-cols-2">
                  <div>
                    Fecha de Pago
                  </div>
                  <div>
                    <div class="arrow" v-if="'date_enroll_payment_fee' == sortColumn" v-bind:class="[ascending ? 'asc' : 'dsc']"></div>
                  </div>
                </div>
              </th>
              <th v-on:click="sortTable('method_enroll_payment_fee')">
                <div class="grid grid-cols-2">
                  <div>
                    Metodo de pago
                  </div>
                  <div>
                    <div class="arrow" v-if="'method_enroll_payment_fee' == sortColumn" v-bind:class="[ascending ? 'asc' : 'dsc']"></div>
                  </div>
                </div>
              </th>
              <th>
                <div class="grid grid-cols-2">
                  <div>
                    Recibo de pago
                  </div>
                </div>
              </th>
              <td></td>
            </tr> 
          </thead>
          <tbody>
            <tr v-for="item in requestsFiltered" :key="item.id">
              <td>{{ item.rfc }}</td>
              <td>{{ item.name +" " +item.middle_name+" "+item.paternal_name+" "+item.maternal_name }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td>{{ $filters.formatDate(item.date_payment) }}</td>
              <td>{{ item.method_payment }}</td>
              <td>
                <center><input v-if="item.has_receipt_enrollment_payment_fee == true" type="button" class="buttonAdd" value="Visualizar Recibo" :disabled="item.has_receipt_enrollment_payment_fee == false"
                v-on:click="showReceipt(item.admission_request_id)"/>
                <h5 v-if="item.has_receipt_enrollment_payment_fee == false">Sin recibo de pago</h5></center>
              </td>
            </tr>
          </tbody>
          </table>
        <div class='pagination-container' style="text-align:center;">
          <nav>
            <ul class="pagination">
              <li data-page="prev" style="cursor: pointer;">
                <span>Anterior<span class="sr-only">(current)</span></span>
              </li>
              <li data-page="next" id="prev" style="cursor: pointer;">
                  <span>Siguiente<span class="sr-only">(current)</span></span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="form-group" style="visibility:hidden"> 	<!--		Show Numbers Of Rows 		-->
            <select class ="form-control" name="state" id="maxRows">
              <option value="5000">Show ALL Rows</option>
              <option value="5">5</option>
            </select>
          </div>
  </div>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css">
</div>
</template>

<script>
import Header from "@/components/Analyst/Header.vue"
import MenuLateral from '@/components/Analyst/MenuLateral.vue'
import Loading from '@/components/Loading/VueLoading.vue'
import "@/assets/css/tailwind.css";
import Swal from "sweetalert2";
import {getTokenDecoden} from "@/helpers/tokenauth";

import { mask } from 'maska';
import {useRouter} from "vue-router";
export default {
  data() {
  return {
      ccapi: process.env.VUE_APP_CCAPI,
      multiApi:process.env.VUE_APP_MULTIAPI,
      activeColor: '1',
      activeColor2: '0.4',
      isLoading: false,
      apiToken: "",
      personId: "",
      currentPage: 1,
      elementsPerPage: 10,
      sortColumn: "",
      ascending: false,
      requests: [],
      requestsFiltered: [],
      requestsCount: 0,
      requestType: "",
      title: "",
      associated: "",
      associates: [],
      rfc: ""
    };
  },
  setup(){
    const router = useRouter();
    return { router }
  },
  filters:  {
    formatDate:  function(value)  {
      if  (!value)  {
        return  "";
      }
      var d = new Date(value)
      console.log("fecha recibida: " + d);
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [day, month, year].join('/');
    },
    currency: function (value) {
      const options2 = { style: 'currency', currency: 'MXN' };
      //const formatter = new Intl.NumberFormat('es-MX', options2);
      const formatter = new Intl.NumberFormat('en-US', options2);

      var resultado = Number(value.replace(/[^0-9.-]+/g,"")).toString();
      
      if(!value.includes(".")){
          resultado = value + ".00"
      }
      
      //if(parseFloat(value) < 9999.99){
      //    resultado = value.replace(",","")
      //}
      if(!resultado.includes(",")){
          resultado = resultado.replace(",","")//formatter.format(value)
      }
      resultado = formatter.format(resultado)
      
      resultado = resultado.replace('MX$','')
      
      if (resultado =="NaN" || resultado < 0) {
          resultado = ""
      }
      return '$' + resultado;
    },
    uppercase: function (value) {
      if(value != "" && value != undefined){
          value = value.toUpperCase();
      }
      return value
    },
    phone: function (value) {
      return value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  },
  name: 'Principal',
  components: {
    Header,
    Loading,
    MenuLateral
  },
  props: {
    msg: String
  },
  watch: {
    rfc(val){
      if (val != "") {
        this.requestsFiltered = this.requests.filter(request => request.rfc.toUpperCase().includes(val.toUpperCase()));
      } else {
        this.requestsFiltered = this.requests;
      }
      this.getPagination('#table-id');
    }
  },
  async mounted() {
    this.isLoading = true;
    this.loadInfo();
    this.getRequests();
  },
  async beforeMount() {
  },
  methods:{
    getRequests() {
      return this.getEnrollPaymentFee().then((response) => {
        this.getPagination('#table-id');
      });
    },
    change_page(page) {
      this.currentPage = page;
    },
    num_pages(){
      return Math.ceil(this.requests.length / this.elementsPerPage);
    },
    get_rows(){
    var start = (this.currentPage-1) * this.elementsPerPage;
      var end = start + this.elementsPerPage;
      return this.requests.slice(start, end);
    },
    async getLoginCredentials() {
      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let data = {
        "foncabsa_user": {
          "email": "ivancastrogtz@gmail.com",
          "password": "cabsa2020"
        }
      }
      return await this.axios.post(this.ccapi + "/login", data, config)
        .then((response) => {
          this.apiToken = response.data.token_auth
          console.log("login: " + JSON.stringify(response));
          return response
      })
    },
    Logout(){
      sessionStorage.clear();
      this.router.push({path:"/"});
      //localStorage.clear();
      // window.location.href = "/"
    },
    getRouterParams() {
      return this.$route.params.requestType
    },
    addUpperCase(value){
      if(value != ""){
          value = value.toUpperCase();
      }
      return value
    },
    getPagination(table) {
      var lastPage = 1;
      var self = this;
      $('#maxRows')
        .on('change', function(evt) {
          //$('.paginationprev').html('');						// reset pagination

        lastPage = 1;
          $('.pagination')
            .find('li')
            .slice(1, -1)
            .remove();
          var trnum = 0; // reset tr counter
          var maxRows = parseInt(self.elementsPerPage); // get Max Rows from select option

          if (maxRows == 5000) {
            $('.pagination').hide();
          } else {
            $('.pagination').show();
          }

          var totalRows = self.requests.length; // numbers of rows
          $(table + ' tr:gt(0)').each(function() {
            // each TR in  table and not the header
            trnum++; // Start Counter
            if (trnum > maxRows) {
              // if tr number gt maxRows

              $(this).hide(); // fade it out
            }
            if (trnum <= maxRows) {
              $(this).show();
            } // else fade in Important in case if it ..
          }); //  was fade out to fade it in
          if (totalRows > maxRows) {
            // if tr total rows gt max rows option
            var pagenum = Math.ceil(totalRows / maxRows); // ceil total(rows/maxrows) to get ..
            //	numbers of pages
            for (var i = 1; i <= pagenum; ) {
              // for each page append pagination li
              $('.pagination #prev')
                .before(
                  '<li data-page="' +
                    i +
                    '">\
                      <span>' +
                    i++ +
                    '<span class="sr-only">(current)</span></span>\
                    </li>'
                )
                .show();
            } // end for i
          } // end if row count > max rows
          $('.pagination [data-page="1"]').addClass('active'); // add active class to the first li
          $('.pagination li').on('click', function(evt) {
            // on click each page
            evt.stopImmediatePropagation();
            evt.preventDefault();
            var pageNum = $(this).attr('data-page'); // get it's number

            var maxRows = parseInt(self.elementsPerPage); // get Max Rows from select option

            if (pageNum == 'prev') {
              if (lastPage == 1) {
                return;
              }
              pageNum = --lastPage;
            }
            if (pageNum == 'next') {
              console.log("last: " + lastPage);
              console.log("li: " + $('.pagination li').length -2);
              if ($('.pagination li').length - 2 == 0) {
                return;
              }
              if (lastPage == $('.pagination li').length - 2) {
                return;
              }
              pageNum = ++lastPage;
            }

            lastPage = pageNum;
            var trIndex = 0; // reset tr counter
            $('.pagination li').removeClass('active'); // remove active class from all li
            $('.pagination [data-page="' + lastPage + '"]').addClass('active'); // add active class to the clicked
            // $(this).addClass('active');					// add active class to the clicked
            self.limitPagging();
            $(table + ' tr:gt(0)').each(function() {
              // each tr in table not the header
              trIndex++; // tr index counter
              // if tr index gt maxRows*pageNum or lt maxRows*pageNum-maxRows fade if out
              if (
                trIndex > maxRows * pageNum ||
                trIndex <= maxRows * pageNum - maxRows
              ) {
                $(this).hide();
              } else {
                $(this).show();
              } //else fade in
            }); // end of for each tr in table
          }); // end of on click pagination list
          self.limitPagging();
        })
        .val(5)
        .change();

      // end of on select change

      // END OF PAGINATION
    },
    limitPagging() {
      if($('.pagination li').length > 7 ){
          if( $('.pagination li.active').attr('data-page') <= 3 ){
          $('.pagination li:gt(5)').hide();
          $('.pagination li:lt(5)').show();
          $('.pagination [data-page="next"]').show();
        }if ($('.pagination li.active').attr('data-page') > 3){
          $('.pagination li:gt(0)').hide();
          $('.pagination [data-page="next"]').show();
          for( let i = ( parseInt($('.pagination li.active').attr('data-page'))  -2 )  ; i <= ( parseInt($('.pagination li.active').attr('data-page'))  + 2 ) ; i++ ){
            $('.pagination [data-page="'+i+'"]').show();

          }

        }
      }
    },
    loadInfo(){
      var auth = getTokenDecoden()
      this.apiToken = sessionStorage.getItem("login")
      this.personId = auth.obj.personnel_info['id'];
    },
    formatDate(value) {
      if  (!value)  {
        return  "";
      }
      var d = new Date(value)
      console.log("fecha recibida: " + d);
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [day, month, year].join('/');
    },
    convertListToArray(list) {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    },
    async getEnrollPaymentFee() {
      this.isLoading = true;
      return await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/enroll_payment_fee/get_list_payments_and_admission_request`, {
        params:{
          token_auth: this.apiToken
        }
      }, { 'Content-Type': 'application/json' })
      .then(response => {
        console.log("pending request: ",response.data);
        this.requests = response.data.response.data.reverse();
        this.requestsFiltered = this.requests;
        this.requestsFiltered.find(request=>{
          request.phone = mask(request.phone, '### ###-####');
        });
        this.requestsCount = this.requests.length;
        this.isLoading = false;
      }).catch(error => {
        this.requests = [];
        this.requestsCount = 0;
        this.isLoading = false;
      });
    },
    sortTable(col) {
      if (this.sortColumn === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = col;
      }

      var ascending = this.ascending;

      this.requests.sort(function(a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0;
      })
    },
    camelize(str) {
      let response = ""
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
        return match.charAt(match.length-1).toUpperCase();
      });
      response = str.charAt(0).toUpperCase() + str.substring(1);
      return response.replace(/([A-Z])/g, ' $1').trim();
    },
    showReceipt(admission_request_id){
      this.isLoading = true;
      return this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/enroll_payment_fee/get_receipt`, {
        params:{
          token_auth: this.apiToken,
          remote_folder: "documentos_alta_socios",
          admission_request_id: admission_request_id
        }
      }, { 'Content-Type': 'application/json' })
      .then(response => {
        this.isLoading = false;
        window.open(response.data.response.link);
      }).catch(error => {
        this.isLoading = false;
        Swal.fire({
          title: "Aviso",
          text: error.response.data.errors[0],
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      });
    }
  }
}
</script>

<style>
.main-layout {
  background:red;
}
.new-request {
  background: red;
}
table, th, td {
  border-collapse: collapse;
}
th, td {
  padding: 15px 10px 15px 10px !important;
}
table, th, td {
  border-bottom: 1px solid #85C875;
}
table thead tr th {
  border-bottom: 1px solid #85C875 !important;
}
tr {
  color: #174A84;
  font-family:lato;
  font-size: 12px;
}
#menu ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 200px;
    font-family: Arial, sans-serif;
    font-size: 11pt;
}

#menu ul {
  width: 100%;
}
#menu ul li {
  font-family: "Roboto";
  border-bottom: 1px solid #85C875;
  color:#174A84;
  display: block; 
  padding-bottom: 0;
  padding-top: 10px;
  padding-left: 30px;
}
.buttonAdd {
    background-color: #4CAF50; /* Green */
    border: none;
    border-radius: 5px;
    color: white;
    padding: 1px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    font-family:Lato;
    widows: 10px;
    cursor: pointer;
    background-color: #174A84;
}
/* Sort Table */
.arrow_down {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAYAAABPY4eKAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1PGlEUHQaiiewslpUJiyYs2yb9AyRuJGm7c0VJoFXSX9A0sSZN04ULF12YEBQDhMCuSZOm1FhTiLY2Rky0QPlQBLRUsICoIN/0PCsGyox26NC3eTNn3r3n3TvnvvsE1PkwGo3yUqkkEQqFgw2Mz7lWqwng7ztN06mxsTEv8U0Aam5u7r5EInkplUol/f391wAJCc7nEAgE9Uwmkzo4OPiJMa1Wq6cFs7Ozt0H6RqlUDmJXfPIx+qrX69Ti4mIyHA5r6Wq1egND+j+IyW6QAUoul18XiUTDNHaSyGazKcZtdgk8wqhUKh9o/OMvsVgsfHJy0iWqVrcQNRUMBnd6enqc9MjISAmRP3e73T9al3XnbWNjIw2+KY1Gc3imsNHR0YV4PP5+d3e32h3K316TySQFoX2WyWR2glzIO5fLTSD6IElLNwbqnFpbWyO/96lCoai0cZjN5kfYQAYi5H34fL6cxWIZbya9iJyAhULBHAqFVlMpfsV/fHxMeb3er+Vy+VUzeduzwWC45XA4dlD/vEXvdDrj8DvURsYEWK3WF4FA4JQP9mg0WrHZbEYmnpa0NxYgPVObm5teiLABdTQT8a6vrwdRWhOcHMzMzCiXlpb2/yV6qDttMpkeshEzRk4Wo/bfoe4X9vb2amzGl+HoXNT29vZqsVi0sK1jJScG+Xx+HGkL4Tew2TPi5zUdQQt9otPpuBk3e0TaHmMDh1zS7/f780S0zX6Yni+NnBj09fUZUfvudDrNZN+GkQbl8Xi8RLRtHzsB9Hr9nfn5+SjSeWUCXC7XPq5kw53wsNogjZNohYXL2EljstvtrAL70/mVaW8Y4OidRO1/gwgbUMvcqGmcDc9aPvD1gnTeQ+0nmaInokRj0nHh+uvIiVOtVvt2a2vLv7Ky0tL3cRTXIcpPAwMDpq6R4/JXE4vFQ5FI5CN+QTaRSFCYc8vLy1l0rge4ARe5kJ/d27kYkLXoy2Jo4C7K8CZOsEBvb+9rlUp1xNXPL7v3IDwxvPD6AAAAAElFTkSuQmCC')
}
.arrow_up {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAwpJREFUSA21Vt1PUmEYP4dvkQ8JFMwtBRocWAkDbiqXrUWXzU1rrTt0bdVqXbb1tbW16C9IBUSmm27cODdneoXjputa6069qwuW6IIBIdLvdaF4OAcOiGeDc87zPs/vd57P96WpFq7p6enbGo1mjKZpeTabjU1MTCRagGnOZHFxcXxtbe1XKpUq7+zslJeXl//Mz8+Hy+Uy3RxSE9qTk5M3otFooVQqgef4Wl9f343FYoEmoISrxuNxFX5f9vb2jhn/PxUKhfLS0tIPfFifUESRUMV8Pv/M6XReRm5rTGQyGeXxeGxYe1ezeBpBOBx2rKysbO7v79d4Wy3Y2Nj4GQqFbgnhaugxwiuGJx99Pp9FLBbXxYTXvTqd7v3MzIy6riIWGxJnMpl7AwMD14xGYyMsSq1WUyQdUqn0eSPlusQIsbGrq+vl4OCgvhFQZd1utyv1en0gEolcqsi47nWJlUrlG5fLZVcoFFy2nDKSDpIWlUoVTCQSEk4lCHmJMZ2GTCbTiMVikfIZ88l7enoos9l8dXt7+z6fDicxSJUokqDX6xXcl2wCROoc0vQCWL3sNfLOSdzR0fHY4XC4tVotl40gmVwup9xuN4OQv+UyqCFGH9rg7SOGYVRcBs3IEG4J0nVnamrqOtvuBDGGgQg9+wHFcVEi4a0LNkbdd6TrPKo8ODc311mteIIYjT/a398/jK+s1jnVM0kXoufCFvq0GuiIGEVgQIhfoygM1QrteEa9dAL7ITiYCt4RMabOK5AyKKzKWtvupLcRciu8D5J0EuDDPyT/Snd39yh6VtY2NhYQSR9G79Ds7OxdskRjEyAufvb7/cPoO5Z6e1+xtVKrq6vfcFzyi/A3ZrPZ3GdNSlwgo5ekE4X2RIQGf2C1WlufFE0GBeGWYQ8YERWLxQtnUVB830MKLZfL9RHir8lkssCn2G751tZWEWe03zTKm15YWPiEiXXTYDB0Ig/t7yd8PRws4EicwWHxO4jHD8/C5HiTTqd1BwcHFozKU89origB+y/kmzgYpgOBQP4fGmUiZmJ+WNgAAAAASUVORK5CYII=')
}
.arrow {
  float: right;
  width: 12px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
}
th.active {
  color: #000;
}
th.active .arrow {
  opacity: 1;
}
.arrow {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0px;
  margin-top:7px;
  opacity: 0.66;
}
.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #174A84;
}
.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #174A84;
}
.arrow-right {
  background: #000;
  height: 3px;
  width: 30px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}
.pagination {
  justify-content: center !important;
}
.number {
  display: inline-block;
  padding: 4px 10px;
  color: #FFF;
  border-radius: 4px;
  background: #44475C;
  margin: 0px 5px;
  cursor: pointer;
}
.number:hover, .number.active {
  background: #717699;
}
select {
  border: 1px solid #174A84;border-radius:5px;padding:3px;
}

@media only screen and (max-width: 1024px)  
{
  table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }
  
  .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }
  
  .width200 tr { border: 1px solid #4CAF50; }
  
  .width200 td { border: none;border-bottom: 1px solid #4CAF50; position: relative;padding-left: 40%;text-align:left }
  
  .width200 td:before {  top: 6px; width: 45%; white-space: nowrap;}

  .width200 td:nth-of-type(1):before { content: "R.F.C.:"; color: #174A84; font-weight: bold; }
  .width200 td:nth-of-type(2):before { content: "Nombre:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(3):before { content: "Teléfono:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(4):before { content: "Correo:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(5):before { content: "Monto a Ahorrar:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(6):before { content: "Puesto/Ocupación:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(7):before { content: "Fecha de Registro:"; color: #174A84; font-weight: bold;}
  
  .descarto {display:none;}
  .fontsize {font-size:10px}
}
</style>